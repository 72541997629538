import React, { useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import ModalList, {
  ModalListItem,
} from "../../../../components/misc/ModalList/ModalList";
import { ScheduleWorkingRoutesNodesListInterface } from "../../../../redux/interfaces/expedition.interface";
import CustomButton from "../../../../components/CustomButton";
import useRoutesHelper from "../../../../hooks/useRoutesHelper";
import {
  addInternodeRouteNode,
  fetchNonExistentNodesForRoute,
  getInternodeRouteNodes,
} from "../../../../redux/api/expedition.api";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../helpers/apiHelper";
import { closeAddNodeModal } from "../../../../redux/reducer/expedition.reducer";

function InternodeRouteAddNodeModal() {
  const dispatch = useAppDispatch();
  const { routeParams } = useRoutesHelper();

  const { idInternod } = routeParams;

  const {
    expedition: {
      newNodesForInternodeRoute: { data: nonExistentNodesToAdd },
      isNonExistingNodesModalOpen,
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const [selectedNode, setSelectedNode] =
    useState<ScheduleWorkingRoutesNodesListInterface | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const initialNonExistentNodesList: ModalListItem[] = useMemo(
    () =>
      nonExistentNodesToAdd?.map((item) => {
        const { id_nod: id, nodNume: label } = item;
        return {
          ...item,
          label: label, // required
          id: id, // required
          backgroundColor: id === selectedNode?.id_nod ? "warning" : "info",
        };
      }),
    [nonExistentNodesToAdd, selectedNode],
  );

  const nonExistentNodesModalActions = useCallback(
    ({ search }: { search: string }) => {
      if (idInternod && isNonExistingNodesModalOpen) {
        dispatch(
          fetchNonExistentNodesForRoute({
            idRutaIntranod: idInternod,
            nodNume: search,
          }),
        );
      }
    },
    [dispatch, idInternod, isNonExistingNodesModalOpen],
  );

  const handleOnSubmit = useCallback(
    async ({ handleClose }: any) => {
      if (!selectedNode || !isNonExistingNodesModalOpen) {
        return;
      }
      setIsLoading(true);

      const resultAction = await dispatch(
        addInternodeRouteNode({
          idNode: selectedNode.id_nod,
          idRutaIntranod: idInternod,
        }),
      );

      if (
        checkEndpointStatus(resultAction, addInternodeRouteNode) !==
        endpointStatus.pending
      ) {
        setIsLoading(false);
      }

      if (
        checkEndpointStatus(resultAction, addInternodeRouteNode) ===
        endpointStatus.fulfilled
      ) {
        dispatch(
          getInternodeRouteNodes({
            idRutaIntranod: idInternod,
          }),
        );
        setSelectedNode(null);
        dispatch(closeAddNodeModal());
        handleClose && handleClose();
      }
    },

    [dispatch, idInternod, isNonExistingNodesModalOpen, selectedNode],
  );

  const onClose = useCallback(() => {
    dispatch(closeAddNodeModal());
    setSelectedNode(null);
  }, [dispatch]);

  return (
    <ModalList
      dynamicModalOpen={!!isNonExistingNodesModalOpen}
      onClose={onClose}
      label="Schimbare nod"
      modalTitle={"Alege nodul pentru a-l adauga rutei internod"}
      searchPlaceholder={"Cauta nod"}
      items={initialNonExistentNodesList}
      modalActions={nonExistentNodesModalActions}
      selectedItem={{
        id: selectedNode?.id_nod || "",
        label: selectedNode?.nodNume || "",
      }}
      setSelectedItem={setSelectedNode}
      withCloseAfterSelect={false}
      CustomModalFooter={({ handleClose }: any) => (
        <div className="d-flex justify-content-between">
          <CustomButton onClick={handleClose}>Inapoi</CustomButton>
          <CustomButton
            variant="contained"
            loading={isLoading}
            onClick={() => {
              handleOnSubmit({ handleClose });
            }}>
            Salveaza
          </CustomButton>
        </div>
      )}
      CustomOpenModalComponent={() => <></>}
    />
  );
}

export default InternodeRouteAddNodeModal;
