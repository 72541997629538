import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import {
  clearAwbHistory,
  clearBinHistory,
  clearWorkingRoutesNodes,
} from "../../../redux/reducer/expedition.reducer";
import {
  getWorkingRoutesNodes,
  getClients,
} from "../../../redux/api/expedition.api";

import CustomButton from "../../../components/CustomButton";
import ContentCard from "../../../components/misc/ContentCard";

import AwbHistoryTable from "./AwbHistoryTable";
import BinHistoryTable from "./BinHistoryTable";

function AwbHistory() {
  const dispatch = useAppDispatch();

  const [selectedType, setSelectedType] = useState<"awb" | "bin">("awb");

  const {
    expedition: {
      workingRoutesNodes: { isLoading: isLoadingNodes },
      clients: { isLoading: isLoadingClients },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  useEffect(() => {
    dispatch(
      getWorkingRoutesNodes({
        page: 1,
        perPage: 50,
      }),
    );

    dispatch(getClients({ page: 1, perPage: 99999 }));

    return () => {
      dispatch(clearWorkingRoutesNodes());
      dispatch(clearAwbHistory());
      dispatch(clearBinHistory());
    };
  }, [dispatch]);

  if (isLoadingNodes || isLoadingClients) return null;

  return (
    <>
      <ContentCard cardBodyClassName="d-flex">
        <CustomButton
          variant={selectedType === "awb" ? "contained" : "outlined"}
          style={{ borderEndEndRadius: 0, borderStartEndRadius: 0 }}
          fullWidth
          onClick={() => setSelectedType("awb")}>
          AWB
        </CustomButton>
        <CustomButton
          variant={selectedType === "bin" ? "contained" : "outlined"}
          style={{ borderEndStartRadius: 0, borderStartStartRadius: 0 }}
          fullWidth
          onClick={() => setSelectedType("bin")}>
          BIN
        </CustomButton>
      </ContentCard>
      {selectedType === "awb" && <AwbHistoryTable />}

      {selectedType === "bin" && <BinHistoryTable />}
    </>
  );
}

export default AwbHistory;
