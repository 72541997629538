import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../../components/CustomButton";
import SvgIcon from "../../../../../helpers/SvgIcon";
import { expeditionRoutes } from "../../../../../router/routesConstants";
import { useAppDispatch } from "../../../../../redux/hooks";
import usePermissions from "../../../../../router/usePermissions";
import userRoles from "../../../../../constants/userRoles";
import { ClientDataInterface } from "../../../../../redux/interfaces/expedition.interface";
import { setExpeditionClientData } from "../../../../../redux/reducer/expedition.reducer";
import { postSetClientStatus } from "../../../../../redux/api/expedition.api";

function ClientsActionButton({
  itemId,
  crudData,
}: {
  itemId: string;
  crudData: ClientDataInterface;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAuthorized } = usePermissions();

  const { CLIENTS_WORKING_POINTS_PERMISSION, CLIENT_DATA_EDIT } = {
    CLIENTS_WORKING_POINTS_PERMISSION: isAuthorized(
      userRoles.CLIENTS_WORKING_POINTS,
    ),

    CLIENT_DATA_EDIT: isAuthorized(userRoles.CLIENT_DATA_EDIT),
  };

  const handleGoToClientWorkingPoints = () => {
    dispatch(setExpeditionClientData(crudData));
    navigate(
      `${itemId}/${expeditionRoutes.clientsWorkingPoints}?denumireClient=${crudData.denumireClient}`,
    );
  };

  const handleEditClient = () => {
    dispatch(setExpeditionClientData(crudData));
    navigate(`${itemId}`);
  };

  const handleGoToClientAwbHistory = () => {
    navigate(
      `/${expeditionRoutes.clients}/${itemId}/${expeditionRoutes.clientAwbHistory}`,
    );
  };

  const handleGoToClientUndeliveredAwb = () => {
    navigate(
      `/${expeditionRoutes.clients}/${itemId}/${expeditionRoutes.undeliveredAwb}`,
    );
  };

  const handleChangeClientStatus = () => {
    dispatch(
      postSetClientStatus({
        idClient: itemId,
        status: crudData.status ? 0 : 1,
      }),
    );
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        tooltipTitle="Istoric AWB/BIN"
        onClick={handleGoToClientAwbHistory}>
        <SvgIcon type="DELIVERED_AWB" />
      </CustomButton>

      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        tooltipTitle="AWB-uri nelivrate"
        onClick={handleGoToClientUndeliveredAwb}>
        <SvgIcon type="UNDELIVERED_AWB" />
      </CustomButton>

      {CLIENTS_WORKING_POINTS_PERMISSION && (
        <CustomButton
          variant="contained"
          className="mt-1 me-1"
          tooltipTitle="Puncte de livrare"
          onClick={handleGoToClientWorkingPoints}>
          <SvgIcon type="WORKING_POINT" />
        </CustomButton>
      )}
      {CLIENT_DATA_EDIT && (
        <>
          <CustomButton
            variant="contained"
            className="mt-1 me-1"
            tooltipTitle="Editează"
            onClick={handleEditClient}>
            <SvgIcon type="EDIT" />
          </CustomButton>
          <CustomButton
            variant="contained"
            color={crudData.status ? "error" : "success"}
            className="mt-1 me-1"
            withConfirmationModal={{
              modalTitle: "Modificare stare client",
              modalSubtitle: `Doriti sa ${
                crudData.status ? "invalidati" : "validati"
              } clientul ${crudData.denumireClient}?`,
              modalLeftButtonVariant: "outlined",
              modalRightButtonOnClick: handleChangeClientStatus,
            }}>
            <SvgIcon type="CHECKBOX" />
          </CustomButton>
        </>
      )}
    </div>
  );
}

export default ClientsActionButton;
