import { createSlice } from "@reduxjs/toolkit";
import {
  ActiveTransportsInterface,
  SearchInvoiceInterface,
} from "../interfaces/raports.interface";
import { fetchActiveTransports, fetchSearchInvoice } from "../api/raports.api";

export const defaultStateRaports: {
  searchInvoice: SearchInvoiceInterface;
  activeTransports: ActiveTransportsInterface;
} = {
  searchInvoice: {
    data: [],
    isLoading: true,
    isError: false,
    errorMessage: "",
  },
  activeTransports: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
};

const raports = createSlice({
  name: "raports",
  initialState: defaultStateRaports,
  reducers: {},
  extraReducers: (builder) => {
    // fetchSearchInvoice  raport start
    builder.addCase(fetchSearchInvoice.pending, (state) => {
      return {
        ...state,
        searchInvoice: {
          ...defaultStateRaports.searchInvoice,
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchSearchInvoice.rejected, (state, action: any) => {
      return {
        ...state,
        searchInvoice: {
          ...state.searchInvoice,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(fetchSearchInvoice.fulfilled, (state, action) => {
      return {
        ...state,
        searchInvoice: {
          ...state.searchInvoice,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // fetchSearchInvoice  raport end

    // fetch active transports start
    builder.addCase(fetchActiveTransports.pending, (state) => {
      return {
        ...state,
        activeTransports: {
          ...defaultStateRaports.activeTransports,
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchActiveTransports.rejected, (state, action: any) => {
      return {
        ...state,
        activeTransports: {
          ...state.activeTransports,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(fetchActiveTransports.fulfilled, (state, action) => {
      return {
        ...state,
        activeTransports: {
          ...state.activeTransports,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // fetch active transports end
  },
});

export default raports.reducer;
