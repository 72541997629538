import { useEffect, useMemo } from "react";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import useRoutesHelper from "../../../../hooks/useRoutesHelper";

import { TableActions } from "../../../../components/tables/tableContext/TableContext";

import {
  getWorkingRoutesNodes,
  fetchUndeliveredAwb,
} from "../../../../redux/api/expedition.api";
import { clearWorkingRoutesNodes } from "../../../../redux/reducer/expedition.reducer";

import {
  getUndeliveredAwbTableData,
  undeliveredAwbTableHeader,
} from "./tableConfig/undeliveredAwbTableConfig";

import TableWidget from "../../../../components/tables/TableWidget";
import UndeliveredAwbTableActionButtons from "./components/TableActionButtonComponent/UndeliveredAwbTableActionButtons";
import RouteTransferModal from "./components/RouteTransferModal";

function UndeliveredAwb() {
  const dispatch = useAppDispatch();
  const { routeMainParam } = useRoutesHelper();

  const { clientId } = routeMainParam as { clientId: string };

  const {
    expedition: {
      undeliveredAwb: {
        data: { data: dataUndeliveredAwb, total: totalUndeliveredAwb },
        isLoading: isLoadingAwb,
      },
      workingRoutesNodes: {
        data: { data: clientsNodesData },
        isLoading: isLoadingNodes,
      },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  useEffect(() => {
    dispatch(
      getWorkingRoutesNodes({
        page: 1,
        perPage: 50,
      }),
    );

    return () => {
      dispatch(clearWorkingRoutesNodes());
    };
  }, [dispatch]);

  const handleAwbTableActions = (action: TableActions) => {
    if (
      clientId &&
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableSearch &&
      action.tableFilter
    ) {
      dispatch(
        fetchUndeliveredAwb({
          idClient: clientId,
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          pctlivrare: action.tableSearch.pctlivrare,
          nodDestinatie: action.tableFilter.nodDestinatie,
          doc_trans: action.tableSearch.doc_trans,
        }),
      );
    }
  };

  const undeliveredAwbTableHeaderNew = useMemo(
    () =>
      undeliveredAwbTableHeader.map((item) => {
        if (!isLoadingNodes && item.value === "nodDestinatie") {
          const nodesDropdown = clientsNodesData?.map(
            ({ nodNume, id_nod }) => ({
              label: nodNume,
              value: id_nod,
            }),
          );
          return {
            withFilter: [{ label: "Toate", value: 0 }, ...nodesDropdown],
            ...item,
          };
        }
        return item;
      }),
    [clientsNodesData, isLoadingNodes],
  );

  return (
    <>
      <RouteTransferModal />
      <TableWidget
        tableTitle="AWB-uri nelivrate"
        tableHeaderData={undeliveredAwbTableHeaderNew}
        tableItemsData={getUndeliveredAwbTableData(dataUndeliveredAwb)}
        totalItems={totalUndeliveredAwb}
        tableLoading={isLoadingAwb}
        handleTableActions={handleAwbTableActions}
        ActionButtonsComponent={(props: any) => (
          <UndeliveredAwbTableActionButtons {...props} />
        )}
        borderedRow
      />
    </>
  );
}

export default UndeliveredAwb;
