import React, { useMemo } from "react";

import countiesAndLocalities from "../constants/judete.json";
import Dropdown from "./misc/Dropdown";

function CountriesAndLocalitiesDropdowns({
  values,
  errors,
  touched,
  setFieldValue,
}: {
  values: { judet?: string; localitate?: string };
  errors: { judet?: string; localitate?: string };
  touched: { judet?: boolean; localitate?: boolean };
  setFieldValue: (field: string, value?: string | number) => void;
}) {
  const getLocaltiesForSelectedCounty = (county?: {
    id: string;
    label: string;
    localitati: {
      nume: string;
      simplu?: string;
      comuna?: string;
    }[];
  }) => {
    if (!county) {
      return [];
    }

    return (
      county?.localitati.map((locality: { nume: string }) => ({
        label: locality.nume,
        id: locality.nume,
      })) || []
    );
  };

  const countiesFormatted = useMemo(() => {
    return countiesAndLocalities.judete.map((county) => ({
      id: county.id,
      label: county.id,
      localitati: county.localitati,
    }));
  }, []);

  const selectedCounty = countiesFormatted.find(
    (county) => county.id === values.judet,
  );

  const localitiesFormatted = getLocaltiesForSelectedCounty(selectedCounty);

  const selectedLocality = localitiesFormatted?.find(
    (locality: { id: string }) => locality.id === values.localitate,
  );

  return (
    <>
      <Dropdown
        title="Judet"
        data={countiesFormatted}
        singleValue={
          selectedCounty || {
            label: "-",
            id: "-",
          }
        }
        setSingleValue={(value) => setFieldValue("judet", value?.id)}
        error={errors["judet"]}
        touched={touched["judet"]}
      />
      {localitiesFormatted?.length > 0 && (
        <Dropdown
          title="Localitate"
          data={localitiesFormatted}
          singleValue={
            selectedLocality || {
              label: "-",
              id: "-",
            }
          }
          setSingleValue={(value) => setFieldValue("localitate", value?.id)}
          error={errors["localitate"]}
          touched={touched["localitate"]}
        />
      )}
    </>
  );
}

export default CountriesAndLocalitiesDropdowns;
