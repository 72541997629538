export const initialMainRoute = "dashboard";

export const adminRoutes = {
  users: "utilizatori",
  createUser: "creaza",
  editUser: "editeaza",
  adminUserWebRoles: "roluri-web",
  adminUserMobileRoles: "roluri-mobile",
  administrateReason: "administrare-motive",
};

export const crudRoutes = {
  create: "creaza",
  edit: "edit",
};

export const fleetRoutes = {
  fleet: "fleet",
  features: "features",
  documents: "documents",
  allDocumets: "toate-documentele",
  carsDocuments: "documente-masini",
};

export const expeditionRoutes = {
  awb: "awb",
  routes: "rute",
  nodes: "noduri",
  workingPoints: "puncte-de-lucru",
  organize: "organizeaza",
  clients: "clienti",
  clientsWorkingPoints: "puncte-de-livrare-clienti",
  clientAwbHistory: "istoric-awb-client",
  undeliveredAwb: "awb-nelivrate",
  internodeRoutes: "rute-internod",
  internodeRouteNodes: "noduri-traseu-internod",
};

export const assignRoutes = {
  assign: "alocare-ruta",
  assignDriver: "asignare-sofer",
};

export const reportsRoutes = {
  searchInvoicesAwb: "cauta-factura-awb",
  activeTransports: "transporturi-active",
};
