import { useAppSelector } from "../redux/hooks";

import {
  adminRoutes,
  assignRoutes,
  crudRoutes,
  expeditionRoutes,
  fleetRoutes,
  reportsRoutes,
} from "./routesConstants";

import userRoles from "../constants/userRoles";

import { Error404 } from "../pages/Error/Error404";
import { Error500 } from "../pages/Error/Error500";
import { ErrorsPage } from "../pages/Error/ErrorPage";
import Users from "../pages/Admin/Users";
import CreateUser from "../pages/Admin/CreateUser";
import AdministrateUserWebRoles from "../pages/Admin/AdministrateUserWebRoles";
import EditUser from "../pages/Admin/EditUser";
import AdministrateUserMobileRoles from "../pages/Admin/AdministrateUserMobileRoles";
import FleetDisplay from "../pages/Fleet/FleetDisplay";
import FleetFeatures from "../pages/Fleet/FleetFeatures";
import FleetDocuments from "../pages/Fleet/FleetDocuments";
import FleetDisplayEdit from "../pages/Fleet/FleetDisplay/FleetDisplayEdit";
import FleetFeaturesEdit from "../pages/Fleet/FleetFeatures/FleetFeaturesEdit";
import FleetDocumentsEdit from "../pages/Fleet/FleetDocuments/FleetDocumentsEdit";
import FleetFeatureDocuments from "../pages/Fleet/FleetFeatures/FleetFeatureDocuments";
import FleetFeatureDocumentsEdit from "../pages/Fleet/FleetFeatures/FleetFeatureDocuments/FleetFeatureDocumentsEdit";
import ExpeditionScheduleWorkingPoints from "../pages/Expedition/ScheduleWorkingPoints";
import ExpeditionScheduleWorkingPointsOrganise from "../pages/Expedition/ScheduleWorkingPoints/ExpeditionScheduleWorkingPointsOrganise";
import ExpeditionScheduleRoutes from "../pages/Expedition/ScheduleWorkingRoutes";
import ExpeditionScheduleRoutesOrganise from "../pages/Expedition/ScheduleWorkingRoutes/ExpeditionScheduleRoutesOrganise";
import NodesRoute from "../pages/Expedition/Nodes";
import NodeOrganise from "../pages/Expedition/Nodes/NodeOrganise";
import AssignDriverToRoute from "../pages/Assign/AssignDriverToRoute";
import AssignDrivers from "../pages/Assign/AssignDrivers";
import SearchInvoices from "../pages/Raports/SearchInvoices";
import FleetAllDocumets from "../pages/Fleet/FleetAllDocuments";
import ClientsWorkingPoints from "../pages/Expedition/ClientsWorkingPoints";
import Clients from "../pages/Expedition/Clients";
import CreateOrUpdateClient from "../pages/Expedition/Clients/CreateOrUpdateClient";
import AdministrateReason from "../pages/Admin/AdministrateReason";
import CreateOrUpdateReason from "../pages/Admin/AdministrateReason/CreateOrUpdateReason";
import ClientAwbHistory from "../pages/Expedition/Clients/ClientAwbHistory";
import UndeliveredAwb from "../pages/Expedition/Clients/UndeliveredAwb";
import AwbHistory from "../pages/Expedition/AWB";
import InternodeRoutes from "../pages/Expedition/InternodeRoutes";
import InternodeRouteNodes from "../pages/Expedition/InternodeRouteNodes";
import ActiveTransports from "../pages/Raports/ActiveTransports";

const errorRoutes = [
  {
    id: "Error",
    path: "error/*",
    element: <ErrorsPage />,
  },
  {
    id: "Error500",
    path: "/error/500",
    element: <Error500 />,
  },
  {
    id: "Error404",
    path: "*",
    element: <Error404 />,
  },
];

const fleet = [
  {
    id: userRoles.PREVIEW_FLEET,
    path: `/${fleetRoutes.fleet}`,
    element: <FleetDisplay />,
  },
  {
    id: userRoles.CAR_CREATE,
    path: `/${fleetRoutes.fleet}/create`,
    element: <FleetDisplayEdit />,
  },
  {
    id: userRoles.CAR_EDIT,
    path: `/${fleetRoutes.fleet}/:carId/edit`,
    element: <FleetDisplayEdit withUpdate={true} />,
  },
  {
    id: userRoles.CAR_FEATURES_DISPLAY,
    path: `/${fleetRoutes.fleet}/:carId/${fleetRoutes.features}`,
    element: <FleetFeatures />,
  },
  {
    id: userRoles.CAR_FEATURE_CREATE,
    path: `/${fleetRoutes.fleet}/:carId/${fleetRoutes.features}/create`,
    element: <FleetFeaturesEdit />,
  },
  {
    id: userRoles.CAR_FEATURE_EDIT,
    path: `/${fleetRoutes.fleet}/:carId/${fleetRoutes.features}/edit`,
    element: <FleetFeaturesEdit withUpdate={true} />,
  },
  {
    id: userRoles.CAR_FEATURES_DISPLAY,
    path: `/${fleetRoutes.fleet}/:carId/${fleetRoutes.features}/:featureId/${fleetRoutes.documents}`,
    element: <FleetFeatureDocuments />,
  },
  {
    id: userRoles.CAR_FEATURE_DOCUMENT_EDIT,
    path: `/${fleetRoutes.fleet}/:carId/${fleetRoutes.features}/:featureId/${fleetRoutes.documents}/:documentId/edit`,
    element: <FleetFeatureDocumentsEdit withUpdate={true} />,
  },
  {
    id: userRoles.CAR_FEATURE_DOCUMENT_CREATE,
    path: `/${fleetRoutes.fleet}/:carId/${fleetRoutes.features}/:featureId/${fleetRoutes.documents}/create`,
    element: <FleetFeatureDocumentsEdit />,
  },
  {
    id: userRoles.CAR_DOCUMENTS_DISPLAY,
    path: `/${fleetRoutes.fleet}/:carId/${fleetRoutes.documents}`,
    element: <FleetDocuments />,
  },
  {
    id: userRoles.CAR_DOCUMENT_CREATE,
    path: `/${fleetRoutes.fleet}/:carId/${fleetRoutes.documents}/create`,
    element: <FleetDocumentsEdit />,
  },
  {
    id: userRoles.CAR_DOCUMENT_EDIT,
    path: `/${fleetRoutes.fleet}/:carId/${fleetRoutes.documents}/edit`,
    element: <FleetDocumentsEdit withUpdate />,
  },
  {
    id: userRoles.CAR_DOCUMENTS_ALL_DISPLAY,
    path: `/${fleetRoutes.fleet}/:carId/${fleetRoutes.allDocumets}`,
    element: <FleetAllDocumets withCarId />,
  },
  {
    id: userRoles.CAR_DOCUMENTS_ALL_DISPLAY,
    path: `/${fleetRoutes.carsDocuments}`,
    element: <FleetAllDocumets />,
  },
];

const expedition = [
  {
    id: userRoles.AWB_HISTORY,
    path: `/${expeditionRoutes.awb}`,
    element: <AwbHistory />,
  },
  {
    id: userRoles.SCHEDULE_WORKING_POINTS,
    path: `/${expeditionRoutes.workingPoints}`,
    element: <ExpeditionScheduleWorkingPoints />,
  },
  {
    id: userRoles.WORKING_POINTS_ORGANIZE,
    path: `/${expeditionRoutes.workingPoints}/${expeditionRoutes.organize}`,
    element: <ExpeditionScheduleWorkingPointsOrganise withUpdate />,
  },
  {
    id: userRoles.WORKING_POINTS_ORGANIZE,
    path: `/${expeditionRoutes.workingPoints}/${crudRoutes.create}`,
    element: <ExpeditionScheduleWorkingPointsOrganise />,
  },
  {
    id: userRoles.SCHEDULE_WORKING_ROUTE,
    path: `/${expeditionRoutes.routes}`,
    element: <ExpeditionScheduleRoutes />,
  },
  {
    id: userRoles.WORKING_ROUTES_ORGANIZE,
    path: `/${expeditionRoutes.routes}/${expeditionRoutes.organize}`,
    element: <ExpeditionScheduleRoutesOrganise withUpdate />,
  },
  {
    id: userRoles.WORKING_ROUTES_ORGANIZE,
    path: `/${expeditionRoutes.routes}/${crudRoutes.create}`,
    element: <ExpeditionScheduleRoutesOrganise />,
  },
  {
    id: userRoles.NODES,
    path: `/${expeditionRoutes.nodes}`,
    element: <NodesRoute />,
  },
  {
    id: userRoles.EDIT_NODES_ROUTES,
    path: `/${expeditionRoutes.nodes}/${expeditionRoutes.organize}`,
    element: <NodeOrganise />,
  },
  {
    id: userRoles.CLIENTS,
    path: `/${expeditionRoutes.clients}`,
    element: <Clients />,
  },
  {
    id: userRoles.CLIENT_DATA_EDIT,
    path: `/${expeditionRoutes.clients}/${crudRoutes.create}`,
    element: <CreateOrUpdateClient />,
  },
  {
    id: userRoles.CLIENT_DATA_EDIT,
    path: `/${expeditionRoutes.clients}/:clientId`,
    element: <CreateOrUpdateClient />,
  },
  {
    id: userRoles.CLIENTS_WORKING_POINTS,
    path: `/${expeditionRoutes.clients}/:clientId/${expeditionRoutes.clientsWorkingPoints}`,
    element: <ClientsWorkingPoints />,
  },
  {
    id: userRoles.CLIENT_AWB_HISTORY,
    path: `/${expeditionRoutes.clients}/:clientId/${expeditionRoutes.clientAwbHistory}`,
    element: <ClientAwbHistory />,
  },
  {
    id: userRoles.UNDELIVERED_AWB,
    path: `/${expeditionRoutes.clients}/:clientId/${expeditionRoutes.undeliveredAwb}`,
    element: <UndeliveredAwb />,
  },
  {
    id: userRoles.INTERNODE_ROUTES,
    path: `/${expeditionRoutes.internodeRoutes}`,
    element: <InternodeRoutes />,
  },
  {
    id: userRoles.INTERNODE_ROUTES,
    path: `/${expeditionRoutes.internodeRoutes}/:clientId/${expeditionRoutes.internodeRouteNodes}`,
    element: <InternodeRouteNodes />,
  },
];

const assign = [
  {
    id: userRoles.ASSIGN_ROUTE,
    path: `/${assignRoutes.assign}`,
    element: <AssignDrivers />,
  },
  {
    id: userRoles.ASSIGN_ROUTE,
    path: `/${assignRoutes.assign}/:driverId/${assignRoutes.assignDriver}`,
    element: <AssignDriverToRoute withUpdate />,
  },
];

const reports = [
  {
    id: userRoles.SEARCH_INVOICES_AWB,
    path: `/${reportsRoutes.searchInvoicesAwb}`,
    element: <SearchInvoices />,
  },
  {
    id: userRoles.ACTIVE_TRANSPORTS,
    path: `/${reportsRoutes.activeTransports}`,
    element: <ActiveTransports />,
  },
];

const admin = [
  {
    id: userRoles.PREVIEW_USERS,
    path: `/${adminRoutes.users}`,
    element: <Users />,
  },
  {
    id: userRoles.CREATE_USER,
    path: `/${adminRoutes.users}/${adminRoutes.createUser}`,
    element: <CreateUser />,
  },
  {
    id: userRoles.EDIT_USER,
    path: `/${adminRoutes.users}/${adminRoutes.editUser}`,
    element: <EditUser />,
  },
  {
    id: userRoles.ADMINISTRATE_USER_ROLES,
    path: `/${adminRoutes.users}/${adminRoutes.adminUserWebRoles}`,
    element: <AdministrateUserWebRoles />,
  },
  {
    id: userRoles.ADMINISTRATE_USER_MOBILE_ROLES,
    path: `/${adminRoutes.users}/${adminRoutes.adminUserMobileRoles}`,
    element: <AdministrateUserMobileRoles />,
  },
  {
    id: userRoles.ADMINISTRATE_REASON,
    path: `/${adminRoutes.administrateReason}`,
    element: <AdministrateReason />,
  },
  {
    id: userRoles.ADMINISTRATE_REASON,
    path: `/${adminRoutes.administrateReason}/${crudRoutes.create}`,
    element: <CreateOrUpdateReason />,
  },
  {
    id: userRoles.ADMINISTRATE_REASON,
    path: `/${adminRoutes.administrateReason}/:reasonId`,
    element: <CreateOrUpdateReason />,
  },
];

function useAppRoutes() {
  const {
    user: { userRoutesAccess },
  } = useAppSelector((state) => ({
    user: state.user,
  }));

  const adminAccess = admin.filter((route) =>
    userRoutesAccess?.includes(route.id),
  );

  return {
    errorRoutes,
    allRoutes: [...adminAccess, ...fleet, ...expedition, ...assign, ...reports],
    fleet: fleet,
    admin: adminAccess,
    expedition: expedition,
    assign: assign,
    reports: reports,
  };
}

export default useAppRoutes;
