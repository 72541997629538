import CustomButton from "../../../../../components/CustomButton";
import SvgIcon from "../../../../../helpers/SvgIcon";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../../helpers/apiHelper";
import {
  deleteReasonNomenclature,
  getReasonsNomenclature,
} from "../../../../../redux/api/admin.api";
import { useAppDispatch } from "../../../../../redux/hooks";
import { adminRoutes } from "../../../../../router/routesConstants";

function AdministrateReasonActionButton({
  itemId,
  crudData,
  tableContext,
}: any) {
  const dispatch = useAppDispatch();
  const handleDeleteReason = async () => {
    const resultAction = await dispatch(
      deleteReasonNomenclature({ id_motiv: itemId }),
    );

    if (
      checkEndpointStatus(resultAction, deleteReasonNomenclature) ===
      endpointStatus.fulfilled
    ) {
      if (
        tableContext.tablePagination.page &&
        tableContext.tablePagination.rowsPerPage
      ) {
        dispatch(
          getReasonsNomenclature({
            page: tableContext.tablePagination.page,
            perPage: tableContext.tablePagination.rowsPerPage,
            denumire: tableContext.tableSearch.denumire,
            type: tableContext.tableFilter.type,
          }),
        );
      }
    }
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        navigate={`/${adminRoutes.administrateReason}/${itemId}`}>
        <SvgIcon type="EDIT" />
      </CustomButton>

      <CustomButton
        variant="contained"
        color="error"
        className="mt-1 me-1"
        withConfirmationModal={{
          modalTitle: "Stergere motiv",
          modalSubtitle: `Doriti sa stergeti motivul: ${crudData.denumire}?`,
          modalLeftButtonVariant: "outlined",
          modalRightButtonOnClick: handleDeleteReason,
        }}>
        <SvgIcon type="DELETE" />
      </CustomButton>
    </div>
  );
}

export default AdministrateReasonActionButton;
