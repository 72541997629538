import { useCallback, useMemo } from "react";
import TableWidget from "../../../components/tables/TableWidget";
import { fetchAwbHistory } from "../../../redux/api/expedition.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import {
  awbHistoryTableHeader,
  getAwbTableData,
} from "./tableConfigs/awbHistoryTableConfig";

function AwbHistoryTable() {
  const dispatch = useAppDispatch();
  const {
    expedition: {
      awbHistory: {
        data: { data: dataAwb, total: totalAwb },
        isLoading: isLoadingAwb,
      },

      workingRoutesNodes: {
        data: { data: clientsNodesData },
      },
      clients: { data: clientsData },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const getHistoryTableHeaderNew = useCallback(
    (initialTableData: any[]) => {
      return initialTableData.map((item) => {
        if (item.value === "nodDestinatie") {
          const nodesDropdown = clientsNodesData?.map(
            ({ nodNume, id_nod }) => ({
              label: nodNume,
              value: id_nod,
            }),
          );
          return {
            withFilter: [{ label: "Toate", value: 0 }, ...nodesDropdown],
            ...item,
          };
        }
        if (item.value === "client") {
          const clientsDropdown = clientsData?.data.map(
            ({ denumireClient, idClient }) => ({
              label: denumireClient,
              value: idClient || 0,
            }),
          );
          return {
            withFilter: [{ label: "Toate", value: "all" }, ...clientsDropdown],
            ...item,
          };
        }

        return item;
      });
    },
    [clientsData, clientsNodesData],
  );

  const handleAwbTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableSearch &&
      action.tableFilter &&
      action.tableFilter.client
    ) {
      console.log(action.tableFilter.client);
      dispatch(
        fetchAwbHistory({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          idClient: action.tableFilter.client,
          pctlivrare: action.tableSearch.pctlivrare,
          nodDestinatie: action.tableFilter.nodDestinatie,
          status: action.tableFilter.status,
          doc_trans: action.tableSearch.doc_trans,
        }),
      );
    }
  };

  const awbHistoryTableHeaderNew = useMemo(
    () => getHistoryTableHeaderNew(awbHistoryTableHeader),
    [getHistoryTableHeaderNew],
  );

  return (
    <TableWidget
      tableHeaderData={awbHistoryTableHeaderNew}
      tableItemsData={getAwbTableData(dataAwb)}
      totalItems={totalAwb}
      tableLoading={isLoadingAwb}
      handleTableActions={handleAwbTableActions}
      borderedRow
    />
  );
}

export default AwbHistoryTable;
