import CustomButton from "../../../../../components/CustomButton";
import SvgIcon from "../../../../../helpers/SvgIcon";
import { fetchDownloadCmr } from "../../../../../redux/api/raports.api";
import { useAppDispatch } from "../../../../../redux/hooks";
import { ActiveTransportsDataInterface } from "../../../../../redux/interfaces/raports.interface";

function ActiveTransportActionButton({
  itemId,
  crudData,
}: {
  itemId: string;
  crudData: ActiveTransportsDataInterface;
  tableContext: any;
}) {
  const dispatch = useAppDispatch();

  const { id_ruta_intranod } = crudData;

  const handleDownloadCmr = () => {
    if (id_ruta_intranod && itemId) {
      dispatch(
        fetchDownloadCmr({
          idTransport: itemId,
        }),
      );
    }
  };

  return (
    <div>
      {id_ruta_intranod ? (
        <CustomButton
          variant="contained"
          className="mt-1 me-1"
          tooltipTitle="Descarca CMR"
          onClick={handleDownloadCmr}>
          <SvgIcon type="DOCUMENT_WITH_DOWNLOAD" />
        </CustomButton>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ActiveTransportActionButton;
