import React from "react";
import Text from "../../../../styleguide/Text";
import CustomButton from "../../../../components/CustomButton";
import SvgIcon from "../../../../helpers/SvgIcon";
import useRoutesHelper from "../../../../hooks/useRoutesHelper";
import { useAppDispatch } from "../../../../redux/hooks";
import { openAddNodeModal } from "../../../../redux/reducer/expedition.reducer";

function CustomHeaderInternodeRouteNodes({
  denumireNod,
  className,
}: {
  className?: string;
  denumireNod?: string;
}) {
  const dispatch = useAppDispatch();
  const { routeMainParam } = useRoutesHelper();

  const idInternod = routeMainParam.clientId;

  const handleAddNewNode = () => {
    if (idInternod) {
      dispatch(openAddNodeModal());
    }
  };

  return (
    <div className={`${className} d-flex justify-content-between`}>
      <Text
        variant="h3"
        component="h3"
        className="card-title mb-4 fw-bolder"
        gutterBottom>
        Noduri traseu {denumireNod}
      </Text>
      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        tooltipTitle="Adauga nod"
        onClick={handleAddNewNode}>
        <SvgIcon type="ADD" />
      </CustomButton>
    </div>
  );
}

export default CustomHeaderInternodeRouteNodes;
