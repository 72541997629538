import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import useRoutesHelper from "../../../hooks/useRoutesHelper";
import { clearInternodeRouteNodes } from "../../../redux/reducer/expedition.reducer";
import InternodeRouteAddNodeModal from "./components/InternodeRouteAddNodeModal";
import ContentCard from "../../../components/misc/ContentCard";
import CustomHeaderInternodeRouteNodes from "./components/CustomHeaderInternodeRouteNodes";
import {
  getInternodeRouteNodes,
  fetchDeleteInternodRouteNode,
  fetchUpdateOrdineNodes,
} from "../../../redux/api/expedition.api";
import CustomButton from "../../../components/CustomButton";
import SvgIcon from "../../../helpers/SvgIcon";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../helpers/apiHelper";

function InternodeRouteNodes() {
  const dispatch = useAppDispatch();

  const { routeParams } = useRoutesHelper();

  const { denumire, idInternod } = routeParams;

  const {
    expedition: {
      internodeRouteNodes: {
        data: { data: internodeRouteNodes },
      },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const [internodeRouteNodesData, setInternodeRouteNodesData] = useState(
    internodeRouteNodes || [],
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (internodeRouteNodes) {
      setInternodeRouteNodesData(internodeRouteNodes);
    }
  }, [internodeRouteNodes]);

  useEffect(() => {
    if (idInternod) {
      dispatch(
        getInternodeRouteNodes({
          idRutaIntranod: idInternod,
        }),
      );
    }

    return () => {
      dispatch(clearInternodeRouteNodes());
    };
  }, [dispatch, idInternod]);

  const deleteNode = async (nodeId: number) => {
    if (!idInternod) return;

    const resultAction = await dispatch(
      fetchDeleteInternodRouteNode({
        idIntranodNoduri: nodeId,
      }),
    );

    if (
      checkEndpointStatus(resultAction, fetchDeleteInternodRouteNode) ===
      endpointStatus.fulfilled
    ) {
      dispatch(
        getInternodeRouteNodes({
          idRutaIntranod: idInternod,
        }),
      );
    }
  };

  const moveNodeDown = (index: number) => {
    if (index >= internodeRouteNodesData.length - 1) {
      return;
    }

    const updatedNodes = [...internodeRouteNodesData];
    [updatedNodes[index], updatedNodes[index + 1]] = [
      updatedNodes[index + 1],
      updatedNodes[index],
    ];

    setInternodeRouteNodesData(updatedNodes);
  };

  const moveNodeUp = (index: number) => {
    if (index < 0) {
      return;
    }

    const updatedNodes = [...internodeRouteNodesData];
    [updatedNodes[index - 1], updatedNodes[index]] = [
      updatedNodes[index],
      updatedNodes[index - 1],
    ];

    setInternodeRouteNodesData(updatedNodes);
  };

  const handleSaveNewArrayOrder = async () => {
    const resultAction = await dispatch(
      fetchUpdateOrdineNodes({
        nodes: internodeRouteNodesData,
        idRutaIntranod: idInternod,
      }),
    );

    if (
      checkEndpointStatus(resultAction, fetchUpdateOrdineNodes) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, fetchUpdateOrdineNodes) ===
      endpointStatus.fulfilled
    ) {
      setIsLoading(false);

      dispatch(
        getInternodeRouteNodes({
          idRutaIntranod: idInternod,
        }),
      );
    }
  };

  return (
    <ContentCard className="w-50 mx-auto" CardHeader={() => <></>}>
      <InternodeRouteAddNodeModal />

      <CustomHeaderInternodeRouteNodes
        className="mb-5"
        denumireNod={denumire}
      />

      <div>
        {internodeRouteNodesData?.map((node, idx, nodes) => (
          <ContentCard
            isLoading={isLoading}
            key={node.id_nod}
            className="pt-1 pb-1"
            cardBodyClassName="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex align-items-center justify-content-center">
              <div className="d-flex flex-column me-5">
                {idx > 0 ? (
                  <p onClick={() => moveNodeUp(idx)}>
                    <SvgIcon
                      type="KEYBOARD_ARROW_UP"
                      className="cursor-pointer"
                    />
                  </p>
                ) : (
                  <div className="mb-10" />
                )}
                {idx < nodes.length - 1 ? (
                  <p onClick={() => moveNodeDown(idx)}>
                    <SvgIcon
                      type="KEYBOARD_ARROW_DOWN"
                      className="cursor-pointer"
                    />
                  </p>
                ) : (
                  <div className="mt-7" />
                )}
              </div>
              <div className="d-flex flex-column justify-content-center  align-items-start">
                <h4 className=" pt-2">{node.nodNume}</h4>
                <p className="py-1">Adresa: {node.adresa || "-"}</p>
              </div>
            </div>
            <CustomButton
              variant="contained"
              color="error"
              withConfirmationModal={{
                modalTitle: `Doriti sa stergeti nodul ${node.nodNume} de pe ruta ${denumire}?`,
                modalLeftButtonColor: "inherit",
                modalRightButtonOnClick: () =>
                  deleteNode(node.id_intranod_noduri),
                modalRightButtonText: "Sterge",
                modalLeftButtonText: "Anuleaza",
              }}>
              <SvgIcon type="DELETE" />
            </CustomButton>
          </ContentCard>
        ))}
      </div>

      <div className="d-flex justify-content-between">
        <div></div>
        <CustomButton
          type="submit"
          variant="contained"
          loading={isLoading}
          disabled={!internodeRouteNodesData || isLoading}
          onClick={handleSaveNewArrayOrder}>
          Salveaza
        </CustomButton>
      </div>
    </ContentCard>
  );
}

export default InternodeRouteNodes;
