import { useCallback } from "react";
import TableWidget from "../../../components/tables/TableWidget";

import { TableActions } from "../../../components/tables/tableContext/TableContext";
import { getClients } from "../../../redux/api/expedition.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { clearScheduleWorkingPoints } from "../../../redux/reducer/expedition.reducer";
import {
  clientsTableHeader,
  getClientsTableData,
} from "./tableConfigs/clientsTableConfig";
import { crudRoutes, expeditionRoutes } from "../../../router/routesConstants";

import ClientsActionButton from "./components/TableActionButtonsComponent/ClientsActionButton";
import FormHeader from "../../../components/misc/FormHeader";

function Clients() {
  const dispatch = useAppDispatch();

  const {
    expedition: {
      clients: { data: clientsData, isLoading },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const handleTableActions = useCallback(
    (action: TableActions) => {
      if (
        action.tablePagination.page &&
        action.tablePagination.rowsPerPage &&
        action.tableSearch &&
        action.tableFilter
      ) {
        dispatch(
          getClients({
            page: action.tablePagination.page,
            perPage: action.tablePagination.rowsPerPage,
            denumireClient: action.tableSearch.denumireClient,
            adresa: action.tableSearch.adresa,
            judet: action.tableSearch.judet,
            localitate: action.tableSearch.localitate,
            status: action.tableFilter.status,
          }),
        );
      }
    },
    [dispatch],
  );

  const handleUnmount = useCallback(() => {
    dispatch(clearScheduleWorkingPoints());
  }, [dispatch]);

  return (
    <TableWidget
      CustomHeaderComponent={() => (
        <FormHeader
          title="Clienti"
          buttonRoute={`/${expeditionRoutes.clients}/${crudRoutes.create}`}
          buttonIcon="ADD"
        />
      )}
      tableHeaderData={clientsTableHeader}
      tableItemsData={getClientsTableData(clientsData?.data)}
      handleTableActions={handleTableActions}
      handleUnmount={handleUnmount}
      totalItems={clientsData.total}
      tableLoading={isLoading}
      ActionButtonsComponent={ClientsActionButton}
      borderedRow
    />
  );
}

export default Clients;
