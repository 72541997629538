import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { InternodeRoutesDataInterface } from "../../../../redux/interfaces/expedition.interface";

export const internodeRoutesTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Id ruta internod",
    value: "id_ruta_internod",
    minW: 125,
  },
  {
    label: "Denumire",
    value: "denumire",
    minW: 125,
  },
  {
    label: "Cod",
    value: "cod",
    minW: 125,
  },
];

export const getInternodeRoutesTableData: any = (
  data: InternodeRoutesDataInterface[],
) =>
  data?.map((item) => {
    return {
      id: item.id_ruta_intranod,
      crudData: item,
      data: [
        {
          title: item.id_ruta_intranod,
        },
        {
          title: item.denumire,
        },
        {
          title: item.cod,
        },
      ],
    };
  });
