import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../../components/tables/tableContext/TableContext";
import { ReasonsNomenclatureDataInterface } from "../../../../../redux/interfaces/admin.interface";
import { getDateAndTime } from "../../../../../utils/dateAndTime";

const reasonsTypes = [
  { value: 1, label: "Retail" },
  { value: 2, label: "Gross" },
];

const getReasonTypesLabel = (status: number) => {
  const getStatus = reasonsTypes.find((item) => item.value === status);
  return getStatus ? getStatus.label : "";
};

export const nomMotiveTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Denumire",
    value: "denumire",
    minW: 125,
    withSearch: true,
  },
  {
    label: "Nume Operator",
    value: "operator_name",
    minW: 125,
  },
  {
    label: "Tip",
    value: "type",
    minW: 125,
    withFilter: reasonsTypes,
  },
  {
    label: "TS Inreg",
    value: "ts_inreg",
    minW: 150,
  },
  {
    label: "TS Operatie",
    value: "ts_operatie",
    minW: 150,
  },
];

export const getNomMotiveTableData = (
  data: ReasonsNomenclatureDataInterface[],
): TableData[] =>
  data.map((item) => {
    return {
      id: item.id_motiv,
      crudData: item,
      data: [
        {
          title: item.denumire,
        },
        {
          title: item.operator_name,
        },
        {
          badges: [
            {
              badgeText: getReasonTypesLabel(item.type),
              badgeColor: "light-info",
            },
          ],
        },
        {
          title: getDateAndTime(item.ts_inreg),
        },
        {
          title: getDateAndTime(item.ts_operatie),
        },
      ],
    };
  });
