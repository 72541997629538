import { memo } from "react";
import { TableItemId } from "../../../../../../components/tables/TableWidget";
import { useAppDispatch } from "../../../../../../redux/hooks";
import { KTSVG } from "../../../../../../helpers/KTSVG";
import CustomButton from "../../../../../../components/CustomButton";
import { UndeliveredAwbDataInterface } from "../../../../../../redux/interfaces/expedition.interface";
import { postAssociateAwbToBin } from "../../../../../../redux/api/expedition.api";
import { setClientSelectedUndeliveredAwb } from "../../../../../../redux/reducer/expedition.reducer";

function UndeliveredAwbTableActionButtons({
  itemId,
  crudData,
}: {
  itemId: TableItemId;
  crudData: UndeliveredAwbDataInterface | null;
}) {
  const dispatch = useAppDispatch();

  const handleAssociateAwb = async () => {
    dispatch(
      postAssociateAwbToBin({
        params: {
          id_doctrans: itemId,
        },
      }),
    );
  };

  const handleButtonPress = () => {
    dispatch(setClientSelectedUndeliveredAwb(crudData));
  };

  return (
    <div className="text-start" style={{ width: 250 }}>
      {crudData?.id_doctrans && (
        <CustomButton
          className="me-1  mb-1"
          variant="contained"
          color="primary"
          tooltipTitle="Asociere AWB"
          withConfirmationModal={{
            modalTitle: "Asociare AWB",
            modalSubtitle: `Doriti sa adaugati coletul la binurile de pe nodul ${crudData.nodDestinatie}?`,
            modalLeftButtonVariant: "outlined",
            modalRightButtonOnClick: handleAssociateAwb,
          }}>
          <KTSVG
            path="/media/svg/expedition/associate.svg"
            className="svg-icon-1"
            fill="white"
          />
        </CustomButton>
      )}
      <CustomButton
        variant="contained"
        className="me-1 mb-1"
        tooltipTitle="Transfer ruta"
        onClick={handleButtonPress}>
        <KTSVG
          path="/media/svg/expedition/route.svg"
          className="svg-icon-1"
          fill="white"
        />{" "}
      </CustomButton>
    </div>
  );
}

export default memo(UndeliveredAwbTableActionButtons);
