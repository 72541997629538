import {
  AnyAction,
  configureStore,
  Dispatch,
  Middleware,
} from "@reduxjs/toolkit";
import loggerMiddleware from "redux-logger";

import appReducer from "../reducer/app.reducer";
import generalReducer from "../reducer/general.reducer";
import globalComponentsReducer from "../reducer/globalComponents.reducer";
import authReducer from "../reducer/auth.reducer";
import userReducer from "../reducer/user.reducer";
import raportsReducer from "../reducer/raports.reducer";
import adminReducer from "../reducer/admin.reducer";
import fleetReducer from "../reducer/fleet.reducer";
import expeditionReducer from "../reducer/expedition.reducer";
import assignReducer from "../reducer/assign.reducer";

const isDebuggerMode = process.env.NODE_ENV !== "production";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    appConfig: appReducer,
    user: userReducer,
    general: generalReducer,
    globalComponents: globalComponentsReducer,
    raports: raportsReducer,
    admin: adminReducer,
    fleet: fleetReducer,
    expedition: expeditionReducer,
    assign: assignReducer,
  },
  devTools: isDebuggerMode,
  middleware: (getDefaultMiddleware) => {
    if (isDebuggerMode) {
      return getDefaultMiddleware().concat(
        loggerMiddleware as Middleware<object, any, Dispatch<AnyAction>>,
      );
    }
    return getDefaultMiddleware();
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
