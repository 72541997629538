import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import useRoutesHelper from "../../../../hooks/useRoutesHelper";

import {
  clearAwbHistory,
  clearBinHistory,
  clearWorkingRoutesNodes,
} from "../../../../redux/reducer/expedition.reducer";
import {
  getWorkingRoutesNodes,
  fetchAwbHistory,
  fetchBinHistory,
} from "../../../../redux/api/expedition.api";

import CustomButton from "../../../../components/CustomButton";
import ContentCard from "../../../../components/misc/ContentCard";
import TableWidget from "../../../../components/tables/TableWidget";
import { TableActions } from "../../../../components/tables/tableContext/TableContext";

import {
  awbHistoryTableHeader,
  getAwbTableData,
} from "./components/tableConfigs/awbHistoryTableConfig";
import {
  binHistoryTableHeader,
  getBinTableData,
} from "./components/tableConfigs/binHistoryTableConfig";

function ClientAwbHistory() {
  const dispatch = useAppDispatch();
  const { routeMainParam } = useRoutesHelper();

  const { clientId } = routeMainParam as { clientId: string };

  const [selectedType, setSelectedType] = useState<"awb" | "bin">("awb");

  const {
    expedition: {
      awbHistory: {
        data: { data: dataAwb, total: totalAwb },
        isLoading: isLoadingAwb,
      },
      binHistory: {
        data: { data: dataBin, total: totalBin },
        isLoading: isLoadingBin,
      },
      workingRoutesNodes: {
        data: { data: clientsNodesData },
        isLoading: isLoadingNodes,
      },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  useEffect(() => {
    dispatch(
      getWorkingRoutesNodes({
        page: 1,
        perPage: 50,
      }),
    );

    return () => {
      dispatch(clearWorkingRoutesNodes());
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearAwbHistory());
      dispatch(clearBinHistory());
    };
  }, [dispatch]);

  const handleAwbTableActions = (action: TableActions) => {
    if (
      clientId &&
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableSearch &&
      action.tableFilter
    ) {
      dispatch(
        fetchAwbHistory({
          idClient: clientId,
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          pctlivrare: action.tableSearch.pctlivrare,
          nodDestinatie: action.tableFilter.nodDestinatie,
          status: action.tableFilter.status,
          doc_trans: action.tableSearch.doc_trans,
        }),
      );
    }
  };

  const handleBinTableActions = (action: TableActions) => {
    if (
      clientId &&
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableSearch
    ) {
      dispatch(
        fetchBinHistory({
          idClient: clientId,
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
        }),
      );
    }
  };

  const awbHistoryTableHeaderNew = useMemo(
    () =>
      awbHistoryTableHeader.map((item) => {
        if (!isLoadingNodes && item.value === "nodDestinatie") {
          const nodesDropdown = clientsNodesData?.map(
            ({ nodNume, id_nod }) => ({
              label: nodNume,
              value: id_nod,
            }),
          );
          return {
            withFilter: [{ label: "Toate", value: 0 }, ...nodesDropdown],
            ...item,
          };
        }
        return item;
      }),
    [clientsNodesData, isLoadingNodes],
  );

  return (
    <>
      <ContentCard cardBodyClassName="d-flex">
        <CustomButton
          variant={selectedType === "awb" ? "contained" : "outlined"}
          style={{ borderEndEndRadius: 0, borderStartEndRadius: 0 }}
          fullWidth
          onClick={() => setSelectedType("awb")}>
          AWB
        </CustomButton>
        <CustomButton
          variant={selectedType === "bin" ? "contained" : "outlined"}
          style={{ borderEndStartRadius: 0, borderStartStartRadius: 0 }}
          fullWidth
          onClick={() => setSelectedType("bin")}>
          BIN
        </CustomButton>
      </ContentCard>
      {selectedType === "awb" && (
        <TableWidget
          tableHeaderData={awbHistoryTableHeaderNew}
          tableItemsData={getAwbTableData(dataAwb)}
          totalItems={totalAwb}
          tableLoading={isLoadingAwb}
          handleTableActions={handleAwbTableActions}
          borderedRow
        />
      )}

      {selectedType === "bin" && (
        <TableWidget
          tableHeaderData={binHistoryTableHeader}
          tableItemsData={getBinTableData(dataBin)}
          totalItems={totalBin}
          tableLoading={isLoadingBin}
          handleTableActions={handleBinTableActions}
          borderedRow
        />
      )}
    </>
  );
}

export default ClientAwbHistory;
