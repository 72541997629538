import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import TextInput from "../../../components/misc/TextInput";
import ContentCard from "../../../components/misc/ContentCard";

import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../helpers/apiHelper";
import {
  getCurrentReason,
  postCreateOrUpdateReason,
} from "../../../redux/api/admin.api";
import { clearCurrentReason } from "../../../redux/reducer/admin.reducer";
import useRoutesHelper from "../../../hooks/useRoutesHelper";
import FormButtons from "../../../components/misc/FormButtons";

interface initialValuesInterface {
  denumire: string;
  type: string | number;
}

const initialValues = {
  denumire: "",
  type: "",
};

const validationSchema = Yup.object({
  denumire: Yup.string().required("Denumirea este obligatorie"),
  type: Yup.string().required("Tipul este obligatoriu"),
});

function CreateOrUpdateReason() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { routeMainParam } = useRoutesHelper();

  const { reasonId } = routeMainParam as { reasonId: string };

  const {
    admin: {
      currentReason: { data: reasonData, isLoading },
    },
  } = useAppSelector((state) => ({
    admin: state.admin,
  }));

  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (reasonId) {
      dispatch(
        getCurrentReason({
          idReason: reasonId,
        }),
      );
    }
  }, [dispatch, reasonId]);

  useEffect(() => {
    if (reasonId && reasonData) {
      setFormikValues({
        denumire: reasonData.denumire,
        type: reasonData.type,
      });
    }
  }, [reasonId, reasonData]);

  useEffect(() => {
    return () => {
      dispatch(clearCurrentReason());
    };
  }, [dispatch]);

  const handleSaveReasonDetails = async (value: initialValuesInterface) => {
    setIsSubmitting(true);

    const resultAction = await dispatch(
      postCreateOrUpdateReason({
        ...value,
        id_motiv: reasonId,
      }),
    );

    if (
      checkEndpointStatus(resultAction, postCreateOrUpdateReason) !==
      endpointStatus.pending
    ) {
      setIsSubmitting(false);
    }

    if (
      checkEndpointStatus(resultAction, postCreateOrUpdateReason) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const title =
    (reasonId ? "Editeaza informatii motiv" : "Adauga informatii motiv") +
    ` ${formikValues.denumire || ""}`;

  return (
    <ContentCard
      cardTitle={title}
      cardHeaderClassName="text-center"
      isLoading={isLoading}
      CardHeader={() => <></>}>
      <Formik
        enableReinitialize
        initialValues={formikValues}
        validationSchema={validationSchema}
        onSubmit={handleSaveReasonDetails}>
        {({ isValid, errors, touched, getFieldProps, handleSubmit }) => {
          return (
            <form className="form w-100" onSubmit={handleSubmit} noValidate>
              <TextInput
                type="text"
                label="Denumire"
                name="denumire"
                error={errors["denumire"]}
                touched={touched["denumire"]}
                inputProps={getFieldProps("denumire")}
                withVerticalSpacer
              />
              <TextInput
                type="text"
                label="Tip"
                name="type"
                error={errors["type"]}
                touched={touched["type"]}
                inputProps={getFieldProps("type")}
                withVerticalSpacer
              />

              <FormButtons
                goBackLabel="Anuleaza"
                goNextLabel="Salveaza"
                handleGoBack={handleCancel}
                nextButtonDisabled={isLoading || isSubmitting || !isValid}
                nextButtonLoading={isSubmitting}
              />
            </form>
          );
        }}
      </Formik>
    </ContentCard>
  );
}

export default CreateOrUpdateReason;
