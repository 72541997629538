import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchActiveTransports } from "../../../redux/api/raports.api";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import TableWidget from "../../../components/tables/TableWidget";
import {
  activeTransportsTableHeader,
  getactiveTransportsTableData,
} from "./components/tableConfigs/activeTransportsTableConfig";
import ActiveTransportActionButton from "./components/TableActionsButtonsComponent/ActiveTransportsActionButton";

function ActiveTransports() {
  const dispatch = useAppDispatch();

  const {
    raports: {
      activeTransports: {
        data: { data: activeTransports },
        isLoading,
      },
    },
  } = useAppSelector((state) => ({
    raports: state.raports,
  }));

  const handleTableActions = (action: TableActions) => {
    dispatch(
      fetchActiveTransports({
        page: action.tablePagination.page,
        perPage: action.tablePagination.rowsPerPage,
      }),
    );
  };

  return (
    <TableWidget
      tableTitle="Transporturi active"
      tableHeaderData={activeTransportsTableHeader}
      tableItemsData={getactiveTransportsTableData(activeTransports)}
      tableLoading={isLoading}
      ActionButtonsComponent={(props: any) => (
        <ActiveTransportActionButton {...props} />
      )}
      handleTableActions={handleTableActions}
      withTablePagination={false}
      borderedRow
    />
  );
}

export default ActiveTransports;
