import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import ModalList, {
  ModalListItem,
} from "../../../../../components/misc/ModalList/ModalList";
import {
  clearScheduleWorkingRoutes,
  setClientSelectedUndeliveredAwb,
} from "../../../../../redux/reducer/expedition.reducer";
import CustomButton from "../../../../../components/CustomButton";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../../helpers/apiHelper";
import { ScheduleWorkingRoutesListInterface } from "../../../../../redux/interfaces/expedition.interface";
import {
  fetchTransferRoute,
  getWorkingRoutes,
} from "../../../../../redux/api/expedition.api";
import useRoutesHelper from "../../../../../hooks/useRoutesHelper";

function RouteTransferModal() {
  const dispatch = useAppDispatch();

  const { routeMainParam } = useRoutesHelper();

  const { clientId } = routeMainParam as { clientId: string };

  const {
    expedition: {
      scheduleWorkingRoutes: {
        data: { data: workingRoutes },
      },
      clientSelectedUndeliveredAwb,
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const [selectedRoute, setSelectedRoute] =
    useState<ScheduleWorkingRoutesListInterface | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (clientSelectedUndeliveredAwb?.id_ruta_transfer) {
      const initialSelectedRoute = workingRoutes.find(
        (workingRoute) =>
          workingRoute.id_ruta ===
          clientSelectedUndeliveredAwb?.id_ruta_transfer,
      );
      if (initialSelectedRoute) {
        setSelectedRoute(initialSelectedRoute);
      }
    }
  }, [clientSelectedUndeliveredAwb, workingRoutes]);

  useEffect(() => {
    return () => {
      dispatch(clearScheduleWorkingRoutes());
      setSelectedRoute(null);
    };
  }, [dispatch]);

  const initialClientsRoutesList: ModalListItem[] = useMemo(
    () =>
      workingRoutes.map((item) => {
        const { id_ruta: id, denumire: label } = item;
        return {
          ...item,
          label: `${label}`, // required
          id: id, // required
          backgroundColor: id === selectedRoute?.id_ruta ? "warning" : "info",
        };
      }),
    [workingRoutes, selectedRoute],
  );

  const workingRoutesModalActions = useCallback(
    ({ search }: { search: string }) => {
      if (clientSelectedUndeliveredAwb?.id_nod) {
        dispatch(
          getWorkingRoutes({
            page: 1,
            perPage: 50,
            route: search,
            id_nod: clientSelectedUndeliveredAwb?.id_nod,
          }),
        );
      }
    },
    [clientSelectedUndeliveredAwb?.id_nod, dispatch],
  );

  const handleOnSubmit = useCallback(
    async ({ handleClose }: any) => {
      if (!clientSelectedUndeliveredAwb || !selectedRoute) {
        return;
      }
      setIsLoading(true);

      const resultAction = await dispatch(
        fetchTransferRoute({
          page: 1,
          perPage: 50,
          id_doctrans: clientSelectedUndeliveredAwb?.id_doctrans,
          id_ruta: selectedRoute.id_ruta,
          idClient: clientId,
        }),
      );

      if (
        checkEndpointStatus(resultAction, fetchTransferRoute) !==
        endpointStatus.pending
      ) {
        setIsLoading(false);
      }

      if (
        checkEndpointStatus(resultAction, fetchTransferRoute) ===
        endpointStatus.fulfilled
      ) {
        setSelectedRoute(null);
        handleClose && handleClose();
      }
    },

    [clientId, clientSelectedUndeliveredAwb, dispatch, selectedRoute],
  );

  const onClose = useCallback(() => {
    dispatch(setClientSelectedUndeliveredAwb(null));
    setSelectedRoute(null);
  }, [dispatch]);

  return (
    <ModalList
      dynamicModalOpen={!!clientSelectedUndeliveredAwb}
      onClose={onClose}
      label="Schimbare ruta"
      modalTitle={"Schimbare ruta"}
      searchPlaceholder={"Cauta ruta"}
      items={initialClientsRoutesList}
      modalActions={workingRoutesModalActions}
      selectedItem={{
        id: selectedRoute?.id_ruta || "",
        label: selectedRoute?.denumire || "",
      }}
      setSelectedItem={setSelectedRoute}
      withCloseAfterSelect={false}
      CustomModalFooter={({ handleClose }: any) => (
        <div className="d-flex justify-content-between">
          <CustomButton onClick={handleClose}>Inapoi</CustomButton>
          <CustomButton
            variant="contained"
            loading={isLoading}
            onClick={() => {
              handleOnSubmit({ handleClose });
            }}>
            Salveaza
          </CustomButton>
        </div>
      )}
      CustomOpenModalComponent={() => <></>}
    />
  );
}

export default RouteTransferModal;
