import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getReasonsNomenclature } from "../../../redux/api/admin.api";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import TableWidget from "../../../components/tables/TableWidget";
import UsersActionButton from "./components/TableActionsButtonsComponents/AdministrateReasonActionButton";
import {
  getNomMotiveTableData,
  nomMotiveTableHeader,
} from "./components/tableConfigs/administrateReasonTableConfig";
import FormHeader from "../../../components/misc/FormHeader";
import { adminRoutes, crudRoutes } from "../../../router/routesConstants";

function AdministrateReason() {
  const dispatch = useAppDispatch();

  const {
    admin: {
      reasonsNomenclature: {
        data: { total, data: reasonNomenclatureData },
        isLoading,
      },
    },
  } = useAppSelector((state) => ({
    admin: state.admin,
  }));

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableFilter &&
      action.tableSearch
    ) {
      dispatch(
        getReasonsNomenclature({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          denumire: action.tableSearch.denumire,
          type: action.tableFilter.type,
        }),
      );
    }
  };

  return (
    <TableWidget
      CustomHeaderComponent={() => (
        <FormHeader
          title="Nomenclator motive"
          buttonRoute={`/${adminRoutes.administrateReason}/${crudRoutes.create}`}
          buttonIcon="ADD"
        />
      )}
      tableHeaderData={nomMotiveTableHeader}
      tableItemsData={getNomMotiveTableData(reasonNomenclatureData)}
      totalItems={total}
      borderedRow
      tableLoading={isLoading}
      ActionButtonsComponent={(props: any) => <UsersActionButton {...props} />}
      handleTableActions={handleTableActions}
    />
  );
}

export default AdministrateReason;
