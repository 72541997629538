import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../components/tables/tableContext/TableContext";
import { BinHistoryDataInterface } from "../../../../redux/interfaces/expedition.interface";

export const binHistoryTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "SN Bin",
    value: "sn_bin",
    minW: 125,
  },
  {
    label: "Nr. CMR",
    value: "nr_cmr",
    minW: 125,
  },
  {
    label: "Client",
    value: "client",
    minW: 125,
  },
  {
    label: "Adresa ridicare",
    value: "adresa_ridicare",
    minW: 125,
  },
  {
    label: "Punct ridicare",
    value: "punct_ridicare",
    minW: 125,
  },
  {
    label: "Nod preluare",
    value: "nod_preluare",
    minW: 125,
  },

  {
    label: "Nod destinatie",
    value: "nodDestinatie",
    minW: 125,
  },
];

export const getBinTableData = (data: BinHistoryDataInterface[]): TableData[] =>
  data.map((item) => {
    return {
      id: item.id_bin,
      crudData: item,
      data: [
        {
          title: item.sn_bin,
        },
        {
          title: item.nr_cmr,
        },
        {
          title: item.client,
        },
        {
          title: item.adresa_ridicare,
        },
        {
          title: item.punct_ridicare,
        },
        {
          title: item.nod_preluare,
        },
        {
          title: item.nod_destinatie,
        },
      ],
    };
  });
