import { createSlice } from "@reduxjs/toolkit";
import {
  ClientInterface,
  ClientDataInterface,
  ClientsInterface,
  ClientsWorkingPointsDataInterface,
  ClientsWorkingPointsInterface,
  ScheduleWorkingPointsDetailsInterface,
  ScheduleWorkingPointsInterface,
  ScheduleWorkingRoutesDetailsInterface,
  ScheduleWorkingRoutesInterface,
  WorkingRoutesNodesInterface,
  AwbHistoryInterface,
  BinHistoryInterface,
  UndeliveredAwbInterface,
  UndeliveredAwbDataInterface,
  InternodeRoutesInterface,
  NonExistentNodesOnRouteInterface,
} from "../interfaces/expedition.interface";

import {
  fetchAwbHistory,
  fetchBinHistory,
  fetchNonExistentNodesForRoute,
  fetchUndeliveredAwb,
  getClient,
  getClients,
  getClientsWorkingPoints,
  getInternodeRouteNodes,
  getInternodeRoutes,
  getScheduleWorkingPoints,
  getScheduleWorkingPointsDetails,
  getWorkingRoutes,
  getWorkingRoutesDetails,
  getWorkingRoutesNodes,
} from "../api/expedition.api";

const initialExpeditionState: {
  scheduleWorkingPoints: ScheduleWorkingPointsInterface;
  scheduleWorkingPointsDetails: ScheduleWorkingPointsDetailsInterface;
  scheduleWorkingRoutes: ScheduleWorkingRoutesInterface;
  scheduleWorkingRoutesDetails: ScheduleWorkingRoutesDetailsInterface;
  workingRoutesNodes: WorkingRoutesNodesInterface;
  clientsWorkingPoints: ClientsWorkingPointsInterface;
  clients: ClientsInterface;
  client: ClientInterface;
  clientSelectedWorkingPoint: ClientsWorkingPointsDataInterface | null;
  awbHistory: AwbHistoryInterface;
  binHistory: BinHistoryInterface;
  undeliveredAwb: UndeliveredAwbInterface;
  clientSelectedUndeliveredAwb: UndeliveredAwbDataInterface | null;
  internodeRoutes: InternodeRoutesInterface;
  internodeRouteNodes: WorkingRoutesNodesInterface;
  newNodesForInternodeRoute: NonExistentNodesOnRouteInterface;
  isNonExistingNodesModalOpen: boolean;
} = {
  scheduleWorkingPoints: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  scheduleWorkingPointsDetails: {
    data: {
      days: [],
      pctLucru: null,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  scheduleWorkingRoutes: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  scheduleWorkingRoutesDetails: {
    data: {
      days: [],
      ruta: null,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  workingRoutesNodes: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  clientsWorkingPoints: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  clients: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  client: {
    data: null,
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  clientSelectedWorkingPoint: null,
  awbHistory: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  binHistory: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  undeliveredAwb: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  clientSelectedUndeliveredAwb: null,
  internodeRoutes: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  internodeRouteNodes: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  newNodesForInternodeRoute: {
    data: [],
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  isNonExistingNodesModalOpen: false,
};

const expeditionReducer = createSlice({
  name: "expedition",
  initialState: initialExpeditionState,
  reducers: {
    clearScheduleWorkingPoints: (state) => {
      return {
        ...state,
        scheduleWorkingPoints: {
          ...initialExpeditionState.scheduleWorkingPoints,
        },
      };
    },
    clearScheduleWorkingRoutes: (state) => {
      return {
        ...state,
        scheduleWorkingRoutes: {
          ...initialExpeditionState.scheduleWorkingRoutes,
        },
      };
    },
    clearWorkingRoutesNodes: (state) => {
      return {
        ...state,
        workingRoutesNodes: {
          ...initialExpeditionState.workingRoutesNodes,
        },
      };
    },
    resetScheduleWorkingPointsDetails: (state) => {
      return {
        ...state,
        scheduleWorkingPointsDetails: {
          ...initialExpeditionState.scheduleWorkingPointsDetails,
        },
      };
    },
    resetScheduleWorkingRoutesDetails: (state) => {
      return {
        ...state,
        scheduleWorkingRoutesDetails: {
          ...initialExpeditionState.scheduleWorkingRoutesDetails,
        },
      };
    },
    setExpeditionClientData: (
      state,
      action: {
        payload: ClientDataInterface;
      },
    ) => {
      return {
        ...state,
        fleetCarData: action.payload,
      };
    },
    setClientSelectedWorkingPoint: (
      state,
      action: {
        payload: ClientsWorkingPointsDataInterface | null;
      },
    ) => {
      return {
        ...state,
        clientSelectedWorkingPoint: action.payload,
      };
    },
    clearClientsWorkingPoints: (state) => {
      return {
        ...state,
        clientsWorkingPoints: initialExpeditionState.clientsWorkingPoints,
      };
    },
    clearClient: (state) => {
      return {
        ...state,
        client: initialExpeditionState.client,
      };
    },
    setClientStatus: (
      state,
      action: {
        payload: {
          idClient: number | string;
          status: number;
        };
      },
    ) => {
      return {
        ...state,
        clients: {
          ...state.clients,
          data: {
            ...state.clients.data,
            data: state.clients.data.data.map((client) => {
              if (client.idClient === action.payload.idClient) {
                return {
                  ...client,
                  status: !!action.payload.status,
                };
              }
              return client;
            }),
          },
        },
      };
    },
    clearAwbHistory: (state) => {
      return {
        ...state,
        awbHistory: initialExpeditionState.awbHistory,
      };
    },
    clearBinHistory: (state) => {
      return {
        ...state,
        binHistory: initialExpeditionState.binHistory,
      };
    },
    removeUndeliveredAwb: (state, action) => {
      return {
        ...state,
        undeliveredAwb: {
          ...state.undeliveredAwb,
          data: {
            ...state.undeliveredAwb.data,
            data: state.undeliveredAwb.data.data.filter(
              (awb) => awb.id_doctrans !== action.payload,
            ),
          },
        },
      };
    },
    setClientSelectedUndeliveredAwb: (
      state,
      action: {
        payload: UndeliveredAwbDataInterface | null;
      },
    ) => {
      return {
        ...state,
        clientSelectedUndeliveredAwb: action.payload,
      };
    },
    clearInternodeRoutes: (state) => {
      return {
        ...state,
        internodeRoutes: initialExpeditionState.internodeRoutes,
      };
    },
    clearInternodeRouteNodes: (state) => {
      return {
        ...state,
        internodeRouteNodes: initialExpeditionState.internodeRouteNodes,
      };
    },
    openAddNodeModal: (state) => {
      state.isNonExistingNodesModalOpen = true;
    },
    closeAddNodeModal: (state) => {
      state.isNonExistingNodesModalOpen = false;
    },
  },
  extraReducers: (builder) => {
    // get schedule working points start
    builder.addCase(getScheduleWorkingPoints.pending, (state) => {
      return {
        ...state,
        scheduleWorkingPoints: {
          ...initialExpeditionState.scheduleWorkingPoints,
          isLoading: true,
        },
      };
    });
    builder.addCase(getScheduleWorkingPoints.rejected, (state, action: any) => {
      return {
        ...state,
        scheduleWorkingPoints: {
          ...state.scheduleWorkingPoints,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getScheduleWorkingPoints.fulfilled, (state, action) => {
      return {
        ...state,
        scheduleWorkingPoints: {
          ...state.scheduleWorkingPoints,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // get schedule working points end

    // get  schedule working routes details start
    builder.addCase(getScheduleWorkingPointsDetails.pending, (state) => {
      return {
        ...state,
        scheduleWorkingPointsDetails: {
          ...initialExpeditionState.scheduleWorkingPointsDetails,
          isLoading: true,
        },
      };
    });
    builder.addCase(
      getScheduleWorkingPointsDetails.rejected,
      (state, action: any) => {
        return {
          ...state,
          scheduleWorkingPointsDetails: {
            ...state.scheduleWorkingPointsDetails,
            isLoading: false,
            errorMessage: action.payload.message,
            isError: true,
          },
        };
      },
    );
    builder.addCase(
      getScheduleWorkingPointsDetails.fulfilled,
      (state, action) => {
        return {
          ...state,
          scheduleWorkingPointsDetails: {
            ...state.scheduleWorkingPointsDetails,
            isLoading: false,
            data: action.payload,
          },
        };
      },
    );
    // end get schedule working points details end
    // get schedule working routes start
    builder.addCase(getWorkingRoutes.pending, (state) => {
      return {
        ...state,
        scheduleWorkingRoutes: {
          ...initialExpeditionState.scheduleWorkingRoutes,
          isLoading: true,
        },
      };
    });
    builder.addCase(getWorkingRoutes.rejected, (state, action: any) => {
      return {
        ...state,
        scheduleWorkingRoutes: {
          ...state.scheduleWorkingRoutes,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });

    builder.addCase(getWorkingRoutes.fulfilled, (state, action) => {
      return {
        ...state,
        scheduleWorkingRoutes: {
          ...state.scheduleWorkingRoutes,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // get schedule working routes end
    // get  schedule working routes details start
    builder.addCase(getWorkingRoutesDetails.pending, (state) => {
      return {
        ...state,
        scheduleWorkingRoutesDetails: {
          ...initialExpeditionState.scheduleWorkingRoutesDetails,
          isLoading: true,
        },
      };
    });
    builder.addCase(getWorkingRoutesDetails.rejected, (state, action: any) => {
      return {
        ...state,
        scheduleWorkingRoutesDetails: {
          ...state.scheduleWorkingRoutesDetails,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getWorkingRoutesDetails.fulfilled, (state, action) => {
      return {
        ...state,
        scheduleWorkingRoutesDetails: {
          ...state.scheduleWorkingRoutesDetails,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    //  get  schedule working routes details end
    // get working routes nodes start
    builder.addCase(getWorkingRoutesNodes.pending, (state) => {
      return {
        ...state,
        workingRoutesNodes: {
          ...initialExpeditionState.workingRoutesNodes,
          isLoading: true,
        },
      };
    });
    builder.addCase(getWorkingRoutesNodes.rejected, (state, action: any) => {
      return {
        ...state,
        workingRoutesNodes: {
          ...state.workingRoutesNodes,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getWorkingRoutesNodes.fulfilled, (state, action) => {
      return {
        ...state,
        workingRoutesNodes: {
          ...state.workingRoutesNodes,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // get working routes nodes end

    // get clients working points start
    builder.addCase(getClientsWorkingPoints.pending, (state) => {
      return {
        ...state,
        clientsWorkingPoints: {
          ...initialExpeditionState.clientsWorkingPoints,
          isLoading: true,
        },
      };
    });
    builder.addCase(getClientsWorkingPoints.rejected, (state, action: any) => {
      return {
        ...state,
        clientsWorkingPoints: {
          ...state.clientsWorkingPoints,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getClientsWorkingPoints.fulfilled, (state, action) => {
      return {
        ...state,
        clientsWorkingPoints: {
          ...state.clientsWorkingPoints,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // get clients working points end

    // get clients start
    builder.addCase(getClients.pending, (state) => {
      return {
        ...state,
        clients: {
          ...initialExpeditionState.clients,
          isLoading: true,
        },
      };
    });
    builder.addCase(getClients.rejected, (state, action: any) => {
      return {
        ...state,
        clients: {
          ...state.clients,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getClients.fulfilled, (state, action) => {
      return {
        ...state,
        clients: {
          ...state.clients,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // get clients end
    // get client start
    builder.addCase(getClient.pending, (state) => {
      return {
        ...state,
        client: {
          ...initialExpeditionState.client,
          isLoading: true,
        },
      };
    });
    builder.addCase(getClient.rejected, (state, action: any) => {
      return {
        ...state,
        client: {
          ...state.client,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getClient.fulfilled, (state, action) => {
      return {
        ...state,
        client: {
          ...state.client,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // get client end
    // get awb history start
    builder.addCase(fetchAwbHistory.pending, (state) => {
      return {
        ...state,
        awbHistory: {
          ...initialExpeditionState.awbHistory,
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchAwbHistory.rejected, (state, action: any) => {
      return {
        ...state,
        awbHistory: {
          ...state.awbHistory,
          isLoading: false,
          errorMessage: action.payload?.message,
          isError: true,
        },
      };
    });
    builder.addCase(fetchAwbHistory.fulfilled, (state, action) => {
      return {
        ...state,
        awbHistory: {
          ...state.awbHistory,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // awb history end
    // bin history start

    builder.addCase(fetchBinHistory.pending, (state) => {
      return {
        ...state,
        binHistory: {
          ...initialExpeditionState.binHistory,
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchBinHistory.rejected, (state, action: any) => {
      return {
        ...state,
        binHistory: {
          ...state.binHistory,
          isLoading: false,
          errorMessage: action.payload?.message,
          isError: true,
        },
      };
    });
    builder.addCase(fetchBinHistory.fulfilled, (state, action) => {
      return {
        ...state,
        binHistory: {
          ...state.binHistory,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // bin history end
    // get undelivered awb start
    builder.addCase(fetchUndeliveredAwb.pending, (state) => {
      return {
        ...state,
        undeliveredAwb: {
          ...initialExpeditionState.undeliveredAwb,
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchUndeliveredAwb.rejected, (state, action: any) => {
      return {
        ...state,
        undeliveredAwb: {
          ...state.undeliveredAwb,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(fetchUndeliveredAwb.fulfilled, (state, action) => {
      return {
        ...state,
        undeliveredAwb: {
          ...state.undeliveredAwb,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // get undelivered awb end

    // get internode routes start
    builder.addCase(getInternodeRoutes.pending, (state) => {
      return {
        ...state,
        internodeRoutes: {
          ...initialExpeditionState.internodeRoutes,
          isLoading: true,
        },
      };
    });
    builder.addCase(getInternodeRoutes.rejected, (state, action: any) => {
      return {
        ...state,
        internodeRoutes: {
          ...state.internodeRoutes,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getInternodeRoutes.fulfilled, (state, action) => {
      return {
        ...state,
        internodeRoutes: {
          ...state.internodeRoutes,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // get internode routes end

    // get internode route nodes start
    builder.addCase(getInternodeRouteNodes.pending, (state) => {
      return {
        ...state,
        internodeRouteNodes: {
          ...initialExpeditionState.internodeRouteNodes,
          isLoading: true,
        },
      };
    });
    builder.addCase(getInternodeRouteNodes.rejected, (state, action: any) => {
      return {
        ...state,
        internodeRouteNodes: {
          ...state.internodeRouteNodes,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getInternodeRouteNodes.fulfilled, (state, action) => {
      return {
        ...state,
        internodeRouteNodes: {
          ...state.internodeRouteNodes,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // get internode route nodes end

    // get nodes to add for internode route start
    builder.addCase(fetchNonExistentNodesForRoute.pending, (state) => {
      return {
        ...state,
        newNodesForInternodeRoute: {
          ...initialExpeditionState.newNodesForInternodeRoute,
          isLoading: true,
        },
      };
    });
    builder.addCase(
      fetchNonExistentNodesForRoute.rejected,
      (state, action: any) => {
        return {
          ...state,
          newNodesForInternodeRoute: {
            ...state.newNodesForInternodeRoute,
            isLoading: false,
            errorMessage: action.payload.message,
            isError: true,
          },
        };
      },
    );
    builder.addCase(
      fetchNonExistentNodesForRoute.fulfilled,
      (state, action) => {
        return {
          ...state,
          newNodesForInternodeRoute: {
            ...state.newNodesForInternodeRoute,
            isLoading: false,
            data: action.payload,
          },
        };
      },
    );
    // get nodes to add for internode route end
  },
});

export const {
  clearScheduleWorkingPoints,
  clearScheduleWorkingRoutes,
  clearWorkingRoutesNodes,
  resetScheduleWorkingPointsDetails,
  resetScheduleWorkingRoutesDetails,
  setExpeditionClientData,
  setClientSelectedWorkingPoint,
  clearClientsWorkingPoints,
  clearClient,
  setClientStatus,
  clearAwbHistory,
  clearBinHistory,
  removeUndeliveredAwb,
  setClientSelectedUndeliveredAwb,
  clearInternodeRouteNodes,
  clearInternodeRoutes,
  openAddNodeModal,
  closeAddNodeModal,
} = expeditionReducer.actions;

export default expeditionReducer.reducer;
